<template>
  <div>
    <dashboard-standard v-if="!isDistributor && !isDistributor_khusus"></dashboard-standard>
    <dashboard-distributor v-else></dashboard-distributor>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import Loading from '@/components/Loading'
import useUserInfo from '@/composables/useUserInfo'

export default {
  name: 'VbDashboard',
  components: {
    DashboardStandard: defineAsyncComponent({
      loader: () => import('./Standard.vue'),
      loadingComponent: Loading,
      timeout: 3000,
    }),
    DashboardDistributor: defineAsyncComponent({
      loader: () => import('./Distributor.vue'),
      loadingComponent: Loading,
      timeout: 3000,
    }),
  },
  setup(props, { emit }) {
    const { isDistributor, isDistributor_khusus } = useUserInfo()

    return {
      isDistributor,
      isDistributor_khusus,
      
    }
  },
}
</script>
